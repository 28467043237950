<template>
  <div>
    <b-overlay
      :show="loading"
      :variant="darkMode?'dark':'white'"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="md"
    >
      <div>
        <div class="misc-inner p-2 p-sm-3 mt-3 container">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              Schools Onboarded 🕵🏻‍♀️
            </h2>
            <p class="mb-1">
              Hello <strong>{{ user?user.first_name:'' }}</strong>, Below are the list of schools you have successfully onboarded.
            </p>
            <p><lenon-button
              label="Logout"
              @onClick="logout"
            /></p>
            <p><lenon-button
              label="Update Bank Details"
              variant="outline-primary"
              icon="PlusIcon"
              @onClick="updateBank"
            /></p>
          </div>
        </div>

        <h2 class="text-center">
          <b-badge variant="success">
            Total Revenue: {{ $store.getters['auth/currency'] }}{{ revenue|currency }}
          </b-badge>

        </h2>
        <div class="text-center mb-3">
          <strong>(20% of all payments by your schools)</strong>
        </div>
        <div class="row justify-content-center">
          <div
            v-for="(account,i) in schools"
            :key="i"
            class="col-md-3"
          >
            <affiliate-school
              icon="HomeIcon"
              :name="`${account.name}(${account.percentage}%)`"
              color="primary"
              :logo="account.logo"
              @click.native="(e)=>selectAccount(account.id)"
            />
          </div>
        </div>
        <div
          v-if="!schools.length"
          class="row justify-content-center"
        >
          <strong>No Schools Onboarded</strong>
        </div>
      </div>
    </b-overlay>
    <affiliate-bank
      :modal-opened="showBankModal"
      @modalClosed="()=>{showBankModal=false}"
    />
  </div>
</template>

<script>
import { BOverlay, BBadge } from 'bootstrap-vue'
import logData from '@/libs/log'
import showToast from '@/lenon/mixins/showToast'
import requiredData from '@/lenon/mixins/requiredData'
import useAppConfig from '@core/app-config/useAppConfig'
import AffiliateSchool from '@/views/dashboard/affiliate/AffiliateSchool.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import AffiliateBank from '@/views/dashboard/affiliate/Bank.vue'

const { skin } = useAppConfig()
export default {
  name: 'AccountsVue',
  components: {
    AffiliateBank,
    LenonButton,
    AffiliateSchool,
    BOverlay,
    BBadge,
  },
  mixins: [showToast, requiredData],
  data() {
    return {
      schools: [],
      loading: false,
      revenue: 0.0,
      showBankModal: false,
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
    user() {
      return this.$store.getters['affiliate/user']
    },
  },
  mounted() {
    this.getSchools()
    this.getRevenue()
    this.getBankDetails()
  },
  methods: {
    updateBank() {
      this.showBankModal = true
    },
    logout() {
      this.$store.commit('affiliate/clearAuth')
      this.$router.push({ name: 'affiliate-login' })
    },
    getSchools() {
      this.loading = true
      this.$http.get('affiliates/schools').then(res => {
        this.schools = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    getRevenue() {
      this.$http.get('affiliates/revenue').then(res => {
        this.revenue = res.data
      })
    },
    getBankDetails() {
      this.$http.get('affiliates/bank-details').then(res => {
        this.$store.commit('affiliate/setBankDetails', res.data)
      })
    },
    selectAccount(schoolId) {
      const school = this.schools.find(s => s.id === schoolId)
      this.loading = true
      this.$http.get(`affiliates/payments/${schoolId}`).then(res => {
        this.$store.commit('affiliate/setPayments', res.data)
        this.$store.commit('affiliate/setSchool', school)
        this.$router.push({ name: 'school-payments', query: { school: school.name } })
      }).catch(err => {
        logData(err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
<!--<style lang="scss">-->
<!--//@import "~hover.css";-->
<!--.hvr-underline-from-left:before {-->
<!--  background: #fa2 !important;-->
<!--}-->
<!--</style>-->
