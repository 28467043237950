<template>
  <validation-observer
    ref="vehicleForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      title="Update Bank Details"
      :show="bankModalOpened"
      :show-overlay="bankFormLoading"
      size="md"
      ok-text="Update"
      :ok-disabled="invalid"
      @onOk="saveBank()"
      @onClose="closeBankModal()"
    >
      <div class="school-bank">
        <div class="mt-2 row justify-content-center">
          <div class="col-12">
            <b-card>
              <div class="mb-1">
                <b>Please add your bank details to receive payments</b>
              </div>
              <div class="row">
                <div class="col-12">
                  <lenon-select
                    v-model="bankToSave.bank_code"
                    rules="required"
                    label="Bank Name"
                    name="bank_code"
                    :options="banks"
                    label-name="name"
                    value-name="id"
                    placeholder="Select your Bank"
                  />
                </div>
                <div class="col-12">
                  <lenon-input
                    v-model="bankToSave.account_number"
                    rules="required"
                    label="Account Number"
                    name="account_number"
                    placeholder="Enter your bank account #"
                  />
                </div>
                <div class="col-12">
                  <lenon-input
                    v-model="bankToSave.account_name"
                    rules="required"
                    label="Account Holder Name"
                    name="account_name"
                    placeholder="eg. Prince Osei"
                  />
                </div>
              </div>
              <div class=" text-bolder text-danger mb-1">
                <small><b>All payouts go to this bank, change anytime.</b></small><br>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'

export default {
  name: 'AffiliateBank',
  components: {
    LenonModal,
    LenonButton,
    LenonInput,
    LenonSelect,
    BCard,
    ValidationObserver,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bankFormLoading: false,
      bankModalOpened: false,
      bankToSave: {
        bank_code: null,
        account_number: null,
        account_name: null,
      },
    }
  },
  computed: {
    bank() {
      const b = this.$store.getters['affiliate/bank']
      return { ...b, id: b.bank_code }
    },
    banks() {
      return this.$store.getters['affiliate/banks']
    },
  },
  watch: {
    modalOpened(opened) {
      this.bankModalOpened = opened
    },
  },
  mounted() {
    this.bankToSave = { ...this.bankToSave, ...this.bank }
  },
  methods: {
    closeBankModal() {
      this.bankModalOpened = false
      this.$emit('modalClosed')
    },
    saveBank() {
      this.bankFormLoading = true
      delete this.bankToSave.id
      this.bankToSave.bank_code = +this.bankToSave.bank_code
      this.$http.post('affiliates/update-bank', this.bankToSave).then(res => {
        this.bankFormLoading = false
        this.$store.commit('affiliate/updateBank', res.data)
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.bankFormLoading = false
        this.showError('Something went wrong, Make sure details are valid, otherwise contact support.')
      })
    },
  },
}
</script>

<style scoped>

</style>
