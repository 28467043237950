<template>
  <b-card
    class="text-center"
    style="cursor: pointer;"
  >
    <lenon-profile-photo
      size="100"
      :photo="logo"
    />
    <h5 class="mb-25 font-weight-bolder">
      {{ name }}
    </h5>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'

export default {
  components: {
    LenonProfilePhoto,
    BCard,
    BAvatar,
  },
  props: {
    logo: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
